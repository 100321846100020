<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Scope of Work - {{ serviceRequest.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceRequest.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-stepper 
            alt-labels
            v-model="steps"
            style="box-shadow: none;"
        >
            <v-layout align-center justify-center>
                <v-row style="display: contents !important;">
                    <v-col>
                        <v-stepper-header
                            style="box-shadow: none; "
                        >
                            <v-stepper-step
                                :complete="steps > 1"
                                step="1"
                            >
                                Check Items
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="steps > 2"
                                step="2"
                            >
                                Check Pricing
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                :complete="steps > 3"
                                step="3"
                            >
                                Proposal
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step 
                                :complete="serviceRequest.status > 7"
                                style="width: 50px;"
                                step="4"
                            >
                                <div style="width: 180px !important; text-align: center;">Technical Specification</div>
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-col>
                </v-row>
            </v-layout>
            <v-stepper-items>

                <!-- STEP 1: CHECK ITEMS  -->

                <v-stepper-content step="1">
                    
                    <JobInformation 
                        :id="serviceRequest.id"
                        :project="projectSelected"
                        :refreshJob="refreshJob"
                        :showQuickAccess="false"
                        :showButtonsCreateEditProject="serviceRequest.status < 6"
                        v-on:changeProject="updateProject"
                    />

                    <v-row 
                        v-if="projectSelected != null && projectSelected != undefined"
                    >
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="padding-left: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                > 
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Items
                                    </v-card-title>

                                    <v-card-text>
                                        <v-row>
                                            <v-col
                                                style="padding-left: 0px !important;"
                                            >
                                                <v-card-text>

                                                    <v-card
                                                        class="mx-auto"
                                                    >
                                                        <v-card-title class="text-h5 lighten-2" style="display: block !important; border-bottom: solid 1px var(--color__cinza)">
                                                            <v-row>
                                                                <v-col 
                                                                    v-if="isProjectPool"
                                                                    cols="12"
                                                                    xl="9"
                                                                    lg="7"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label style="font-size: 14px;">Templates</label>
                                                                    <v-combobox
                                                                        v-model="templateSelected"
                                                                        :items="listTemplate"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        dense
                                                                        clearable
                                                                        outlined
                                                                    ></v-combobox>
                                                                </v-col>
                                                                <v-col
                                                                    v-if="isProjectPool"
                                                                    cols="12"
                                                                    xl="3"
                                                                    lg="5"
                                                                    md="6"
                                                                    sm="6"
                                                                    style="display: flex; align-content: center; align-items: center;"
                                                                >
                                                                    <v-tooltip left>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn 
                                                                                :style="$vuetify.breakpoint.mobile == true ? 'width: 95% !important; font-size: 12px !important;' : 'font-size: 12px !important;'"
                                                                                class="mx-2"
                                                                                small
                                                                                v-on="on"
                                                                                :disabled="templateSelected == null || templateSelected == undefined"
                                                                                @click="confirmationApplyTemplate()"
                                                                            >
                                                                                Use Template
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Apply the template selected to the items bellow</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    :style="$vuetify.breakpoint.mobile == true ? 'padding-top: 20px;' : ''"
                                                                    cols="12"
                                                                    lg="12"
                                                                    md="12"
                                                                    sm="12"
                                                                >
                                                                    <v-text-field
                                                                        v-model="searchProduct"
                                                                        label="Search Product"
                                                                        dense
                                                                        prepend-inner-icon="mdi mdi-magnify"
                                                                        single-line
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-treeview
                                                                :items="listTreeViewProduct"
                                                                :search="searchProduct"
                                                                :open.sync="arrayNodeProduct"
                                                                @update:open="onOpenTreeViewProduct"
                                                                :transition="true"
                                                                open-on-click
                                                                rounded
                                                                hoverable
                                                            >
                                                                <template v-slot:prepend="{ item }">
                                                                    <v-icon style="color: var(--color__scopeofwork_category);" v-if="item.type == 'Category'">
                                                                        mdi-shape-outline
                                                                    </v-icon>
                                                                    <v-icon style="color: var(--color__scopeofwork_subcategory);" v-if="item.type == 'SubCategory'">
                                                                        mdi-content-duplicate
                                                                    </v-icon>
                                                                    <v-icon :style="getStyleColor(item)" v-if="item.type == 'Product'">
                                                                        mdi-package-variant-closed
                                                                    </v-icon>
                                                                </template>
                                                                <template v-slot:label="{ item }">                                                                    
                                                                    <v-tooltip 
                                                                        bottom
                                                                    >
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-row
                                                                                v-on="on"
                                                                                :style="userLoggedGetters.profile.systemAdmin == 1 ? 'cursor: pointer;' : ''"
                                                                            >
                                                                                <v-col
                                                                                    cols="9"
                                                                                    style="display: flex; align-items: center;"
                                                                                    @dblclick="openProductForm(item)"
                                                                                >
                                                                                    <span :style="getStyleColor(item)"> 
                                                                                        {{ item.name }} 
                                                                                        <span style="font-size: 20px; color: red; font-weight: bold;" v-if="isProductMandatory(item) == true || isCategoryOrSubCategoryMandatory(item) == true"> *</span>
                                                                                        <span style="font-size: 10px;" v-if="item.details != '' && item.type == 'Product'"><br />{{ item.details }}</span>

                                                                                        <v-tooltip 
                                                                                            bottom
                                                                                            v-if="item.highlight == 1"
                                                                                        >
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-icon 
                                                                                                    v-on="on"
                                                                                                    v-if="item.highlight == 1"
                                                                                                    style="color: var(--color__alert); margin-left: 5px;" 
                                                                                                >
                                                                                                    mdi-alert-octagram-outline
                                                                                                </v-icon>
                                                                                            </template>
                                                                                            <span>{{ item.highlightDescription }}</span>
                                                                                        </v-tooltip>

                                                                                        <v-tooltip 
                                                                                            bottom
                                                                                            v-if="item.customOrder == 1"
                                                                                        >
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-icon 
                                                                                                    v-on="on"
                                                                                                    v-if="item.customOrder == 1"
                                                                                                    style="color: var(--color__alert); margin-left: 5px;" 
                                                                                                >
                                                                                                    mdi-package-variant-plus
                                                                                                </v-icon>
                                                                                            </template>
                                                                                            <span>Custom Order</span>
                                                                                        </v-tooltip>

                                                                                        <v-tooltip 
                                                                                            bottom
                                                                                            v-if="item.stylable == 1"
                                                                                        >
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-icon 
                                                                                                    v-on="on"
                                                                                                    v-if="item.stylable == 1"
                                                                                                    style="color: var(--color__blue); margin-left: 5px;" 
                                                                                                >
                                                                                                    mdi-palette-swatch-outline
                                                                                                </v-icon>
                                                                                            </template>
                                                                                            <span>Stylable</span>
                                                                                        </v-tooltip>
                                                                                        
                                                                                    </span>
                                                                                </v-col>
                                                                                <v-col
                                                                                    style="text-align:right;"
                                                                                    v-if="item.type == 'Category' || item.type == 'SubCategory'"
                                                                                    cols="3"
                                                                                >
                                                                                    {{ item.children.filter(chd => chd.qty != "0.00" && chd.qty != "").length }} / {{ item.children.length }}
                                                                                </v-col>
                                                                                <v-col
                                                                                    v-if="item.type == 'Product'"
                                                                                    cols="3"
                                                                                >
                                                                                    <v-text-field
                                                                                        v-if="item.unityFieldType == 1"
                                                                                        :id="'productQty' + item.idTable"
                                                                                        name="qtyField"
                                                                                        style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                                                        v-model="item.qty"
                                                                                        v-formatMoney="unitProductFormat"
                                                                                        :suffix="item.unitySymbol"
                                                                                        :rules=[validations.mandatoryProduct(item)]
                                                                                        dense
                                                                                        single-line
                                                                                        outlined
                                                                                        @blur="saveItems(true); updateItemsProduct();"
                                                                                    >
                                                                                    </v-text-field>
                                                                                    <v-text-field
                                                                                        v-else
                                                                                        :id="'productQty' + item.idTable"
                                                                                        name="qtyField"
                                                                                        style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                                                        v-mask="'####'"
                                                                                        v-model="item.qty"
                                                                                        :suffix="item.unitySymbol"
                                                                                        :rules=[validations.mandatoryProduct(item)]
                                                                                        dense
                                                                                        single-line
                                                                                        outlined
                                                                                        @blur="saveItems(true); updateItemsProduct();"
                                                                                    >
                                                                                    </v-text-field>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </template>
                                                                        <span>{{ getDescriptionToolTipProduct(item) }}</span>
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-treeview>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                md="6"
                                                style="padding-left: 0px !important;"
                                            >
                                                <v-card-text
                                                    style="margin-top: 10px;"
                                                >
                                                    <v-card
                                                        class="mx-auto"
                                                    >
                                                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                                            <v-col
                                                                cols="9"
                                                            >
                                                                Selected Items
                                                            </v-col>
                                                            <v-col
                                                                style="text-align:right;"
                                                                cols="3"
                                                            >
                                                                <v-tooltip top v-if="!showMoreDetailProductsSW">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon v-on="on" @click="showMoreDetailProductsSW = true"  v-if="!showMoreDetailProductsSW">mdi-eye-off</v-icon>
                                                                    </template>
                                                                    <span>See more details</span>
                                                                </v-tooltip>
                                                                <v-tooltip top v-if="showMoreDetailProductsSW">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon v-on="on" @click="showMoreDetailProductsSW = false" v-if="showMoreDetailProductsSW">mdi-eye</v-icon>
                                                                    </template>
                                                                    <span>Hide more details</span>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-card-title>
                                                        <v-card-text style="margin-top: 20px;">
                                                            <v-simple-table
                                                                fixed-header
                                                                style="cursor: pointer;"
                                                            >
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left" style="width: 20%">
                                                                                Category / Sub Category
                                                                            </th>
                                                                            <th class="text-left" style="width: 20%">
                                                                                Product
                                                                            </th>
                                                                            <th class="text-center" style="width: 5%">
                                                                                Qty
                                                                            </th>
                                                                            <th class="text-left" style="width: 10%">
                                                                                Option
                                                                            </th>
                                                                            <th class="text-center" style="width: 10%">
                                                                                Not Automatable?
                                                                            </th>
                                                                            <th class="text-right" style="width: 13%" v-if="showMoreDetailProductsSW">
                                                                                Price
                                                                            </th>
                                                                            <th class="text-right" style="width: 13%" v-if="showMoreDetailProductsSW">
                                                                                Total
                                                                            </th>
                                                                            <th class="text-center" style="width: 2%">
                                                                                &nbsp;
                                                                            </th>
                                                                            <th class="text-center" style="width: 2%">
                                                                                &nbsp;
                                                                            </th>
                                                                            <th class="text-center" style="width: 13%">
                                                                                &nbsp;
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            v-for="(item, index) in listProductSelecteds.filter(prd => prd.automation == false || prd.notAutomatable == 1)" :key="index"
                                                                            @click="focusInput(item)"
                                                                        >
                                                                            <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                                            <td>{{ item.productDescription }}</td>
                                                                            <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                                            <td class="text-center">
                                                                                <v-tooltip 
                                                                                    right
                                                                                >
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-switch
                                                                                            v-model="item.optional"
                                                                                            color="var(--color__main)"
                                                                                            :value=0
                                                                                            @change="updateOptional(item)"
                                                                                            v-on="on"
                                                                                        >
                                                                                        </v-switch>
                                                                                    </template>
                                                                                    <span>Set item as option</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <v-tooltip 
                                                                                    right
                                                                                    v-if="item.notAutomatable == 1"
                                                                                >
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-switch
                                                                                            v-if="item.notAutomatable == 1"
                                                                                            v-model="item.notAutomatable"
                                                                                            color="var(--color__main)"
                                                                                            :value=0
                                                                                            @change="updateNotAutomatable(item)"
                                                                                            v-on="on"
                                                                                        >
                                                                                        </v-switch>
                                                                                    </template>
                                                                                    <span>Set item as not automatable</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td class="text-right" v-if="showMoreDetailProductsSW">$ {{ item.price }}</td>
                                                                            <td class="text-right" v-if="showMoreDetailProductsSW">$ {{ item.total }}</td>
                                                                            <td class="text-left">
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>Delete Item</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            :style="((item.note != null && item.note != undefined && item.note != '') || (item.styling != null && item.styling != undefined && item.styling != '')) ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showItemNote(item, 'PRODUCT')">mdi-message-bulleted</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span> {{ item.stylable == 1 ? 'Add/Remove notes and styling' : 'Add/Remove Notes' }}</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>View Image</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>View Docs</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot 
                                                                        v-if="showMoreDetailProductsSW"
                                                                    >

                                                                        <tr>
                                                                            <th class="text-left"></th>
                                                                            <th class="text-left"></th>
                                                                            <th class="text-left"></th>
                                                                            <th class="text-left"></th>
                                                                            <th class="text-right" style="color: var(--color__main); font-size: 12px;">
                                                                                Total
                                                                            </th>
                                                                            <th 
                                                                                class="text-center" 
                                                                                style="color: var(--color__main); font-size: 12px;"
                                                                            >
                                                                                {{ grandTotalProducts }}
                                                                            </th>
                                                                            <th class="text-left"></th>
                                                                            <th class="text-left"></th>
                                                                            <th class="text-left"></th>
                                                                        </tr>                                                                        
                                                                    </tfoot>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card-text>

                                                    </v-card>
                                                    
                                                </v-card-text>

                                                <v-card-text
                                                    style="margin-top: 10px;"
                                                >
                                                    <v-card
                                                        class="mx-auto"
                                                    >
                                                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                                            Automation
                                                        </v-card-title>
                                                        <v-card-text style="margin-top: 20px;">
                                                            <label>Automation Controller</label>
                                                            <v-simple-table
                                                                fixed-header
                                                                style="cursor: pointer;"
                                                            >
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Sub Category
                                                                            </th>
                                                                            <th class="text-left" >
                                                                                Product
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Qty
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Channel Qty
                                                                            </th>
                                                                            <th class="text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                            <th class="text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                            <th class="text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            v-for="(item, index) in listProductSelecteds.filter(prd => prd.automationCenter == 1)" :key="index"
                                                                            @click="focusInput(item)"
                                                                        >
                                                                            <td>{{ item.subCategoryDescription }}</td>
                                                                            <td>{{ item.productDescription }}</td>
                                                                            <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                                            <td class="text-center">{{ item.channelQty }}</td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>Delete Item</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            :style="item.note != null && item.note != undefined && item.note != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showItemNote(item, 'PRODUCT')">mdi-message-bulleted</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>Add/Remove Notes</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>View Image</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>View Docs</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card-text>

                                                        <v-card-text style="margin-top: 20px;">
                                                            <label>Automated Items</label><label :style="automationItemsIsValid == false ? 'color: var(--color__red);' : ''">- [{{ qtyChannels }} channels available]</label>
                                                            <v-simple-table
                                                                fixed-header
                                                                style="cursor: pointer;"
                                                            >
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Sub Category
                                                                            </th>
                                                                            <th class="text-left" >
                                                                                Product
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Qty
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Not Automatable?
                                                                            </th>
                                                                            <th class="text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                            <th class="text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                            <th class="text-center">
                                                                                &nbsp;
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            v-for="(item, index) in listProductSelecteds.filter(prd => prd.automatable == 1 && prd.notAutomatable == 0)" :key="index"
                                                                            @click="focusInput(item)"
                                                                        >
                                                                            <td>{{ item.subCategoryDescription }}</td>
                                                                            <td>{{ item.productDescription }}</td>
                                                                            <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                                            <td class="text-center">
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-switch
                                                                                            v-on="on"
                                                                                            v-model="item.notAutomatable"
                                                                                            color="var(--color__main)"
                                                                                            :value=0
                                                                                            @change="updateNotAutomatable(item)"
                                                                                        >
                                                                                        </v-switch>
                                                                                    </template>
                                                                                    <span>Set item as not automatable</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>Delete Item</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            :style="item.note != null && item.note != undefined && item.note != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showItemNote(item, 'PRODUCT')">mdi-message-bulleted</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>Add/Remove Notes</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>View Image</span>
                                                                                </v-tooltip>

                                                                                <v-tooltip right>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn 
                                                                                            class="btnActions"
                                                                                            style="width: 35px; height:35px !important;"
                                                                                            icon 
                                                                                            small 
                                                                                            outlined
                                                                                            v-on="on"
                                                                                        >
                                                                                            <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <span>View Docs</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card-text>

                                                    </v-card>
                                                    
                                                </v-card-text>

                                                <v-card-text>

                                                    <v-card
                                                        class="mx-auto"
                                                        style="height: 220px !important;"
                                                    >
                                                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                                            Notes
                                                        </v-card-title>
                                                        <v-card-text style="margin-top: 20px;">
                                                            <v-textarea
                                                                v-model="serviceRequest.notesScopeOfWork"
                                                                rows="3"
                                                                row-height="25"
                                                                placeholder="Add notes here"
                                                                auto-grow
                                                                outlined
                                                            >
                                                            </v-textarea>
                                                        </v-card-text>

                                                    </v-card>
                                                    
                                                </v-card-text>

                                                <v-dialog
                                                    v-model="showDialogItemNote"
                                                    transition="dialog-top-transition"
                                                    persistent
                                                    width="800"
                                                    :fullscreen="$vuetify.breakpoint.mobile"
                                                >
                                                
                                                    <v-card>
                                                        <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                                            {{ itemDescription }}
                                                        </v-card-title>

                                                        <v-card-text style="margin-top: 20px;">
                                                            <v-row>
                                                                <v-col 
                                                                    cols="12"
                                                                    lg="12"
                                                                    md="12"
                                                                    sm="12"
                                                                >
                                                                    <label>Notes</label>
                                                                    
                                                                    <v-textarea
                                                                        v-model="itemNote"
                                                                        single-line
                                                                        rows="3"
                                                                        row-height="30"
                                                                        outlined
                                                                    >
                                                                    </v-textarea>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row
                                                                v-if="isStylable == true"
                                                            >
                                                                <v-col 
                                                                    cols="12"
                                                                    lg="12"
                                                                    md="12"
                                                                    sm="12"
                                                                >
                                                                    <label>Styling</label>
                                                                    
                                                                    <v-textarea
                                                                        v-model="itemStyling"
                                                                        single-line
                                                                        rows="3"
                                                                        row-height="30"
                                                                        outlined
                                                                    >
                                                                    </v-textarea>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>

                                                        <v-divider></v-divider>

                                                        <v-card-actions>
                                                            <div style="width: 100%; text-align: center;">
                                                                <v-btn
                                                                    class="mx-2 buttonCancelDialog"
                                                                    style=""
                                                                    outlined
                                                                    @click="showDialogItemNote = false"
                                                                >
                                                                    Cancel
                                                                </v-btn>

                                                                <v-btn
                                                                    class="mx-2"
                                                                    outlined
                                                                    @click="saveItemNote()"
                                                                >
                                                                    Save
                                                                </v-btn>
                                                            </div>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-col>

                                        </v-row>  
                                    </v-card-text>

                                </v-card>

                            </v-card-text>
                        </v-col>
                    </v-row>

                </v-stepper-content>

                <!-- STEP 2: CHECK PRICES  -->

                <v-stepper-content step="2">
                    
                    <JobInformation 
                        :id="serviceRequest.id"
                        :showJobSummary="true"
                        :project="projectSelected"
                        :refreshJob="refreshJob"
                        :showQuickAccess="false"
                        :showButtonsCreateEditProject="serviceRequest.status < 6"
                        v-on:changeProject="updateProject"
                    />
                    
                    <!-- PRODUCTS -->
                    <v-row 
                        v-if="projectSelected != null && projectSelected != undefined"
                    >
                        <v-col
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-row>
                                            <v-col
                                                cols="9"
                                            >
                                                Selected Items
                                            </v-col>
                                            <v-col
                                                style="text-align:right;"
                                                cols="3"
                                            >
                                                <v-tooltip top v-if="!showMoreDetailProducts">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" @click="showMoreDetailProducts = true"  v-if="!showMoreDetailProducts">mdi-eye-off</v-icon>
                                                    </template>
                                                    <span>See more details</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="showMoreDetailProducts">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" @click="showMoreDetailProducts = false" v-if="showMoreDetailProducts">mdi-eye</v-icon>
                                                    </template>
                                                    <span>Hide more details</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 20%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Description
                                                        </th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                        <th class="text-right" style="width: 10%" v-if="showMoreDetailProducts">
                                                            Price
                                                        </th>
                                                        <th class="text-right" style="width: 10%" v-if="showMoreDetailProducts">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automation == false || prd.notAutomatable == 1)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                        <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                        <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot v-if="qtyChannels == 0">
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" v-if="showMoreDetailProducts" style="color: var(--color__main); font-size: 20px;">
                                                            Discount
                                                        </th>
                                                        <th 
                                                            v-if="showMoreDetailProducts"
                                                            style="display: flex;"
                                                        >
                                                            <v-text-field
                                                                name="discount"
                                                                v-model="discount"
                                                                suffix=" %"
                                                                v-formatMoney="percFormat"
                                                                :rules=[validations.required]
                                                                single-line
                                                                outlined
                                                                dense
                                                                @blur="calculateTotal('d')"
                                                            >
                                                            </v-text-field>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                            {{ showMoreDetailProducts ? 'Adjustment' : 'Grand Total' }}
                                                        </th>
                                                        <th 
                                                            v-if="showMoreDetailProducts"
                                                            style="display: flex;"
                                                        >
                                                            <v-text-field
                                                                name="grandTotal"
                                                                v-model="grandTotalProducts" 
                                                                v-formatMoney="moneyFormat"
                                                                :rules=[validations.required]
                                                                single-line
                                                                outlined
                                                                dense
                                                                @blur="calculateTotal('v')"
                                                            >
                                                            </v-text-field>
                                                        </th>
                                                        <th 
                                                            v-else
                                                            class="text-center" 
                                                            style="color: var(--color__main); font-size: 20px;"
                                                        >
                                                            {{ grandTotalProducts }}
                                                        </th>
                                                    </tr>
                                                    <tr v-if="showMoreDetailProducts">
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px;">
                                                            Budget
                                                        </th>
                                                        <th class="text-right"  style="width: 15%; color: var(--color__main); font-size: 20px;">
                                                            {{ serviceRequest.estimatedBudgetFormatted }}
                                                        </th>
                                                    </tr>
                                                    <tr v-if="showMoreDetailProducts">
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px;">
                                                            Zillow
                                                        </th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px;">
                                                            {{ estimatedValueZillow }}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col
                            v-if="qtyChannels > 0"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Automation
                                    </v-card-title>

                                    <v-card-text 
                                        style="margin-top: 20px;"
                                    >
                                        <label>Automation Controller</label>

                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 20%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Description
                                                        </th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                        <th class="text-right" style="width: 10%" v-if="showMoreDetailProducts">
                                                            Price
                                                        </th>
                                                        <th class="text-right" style="width: 10%" v-if="showMoreDetailProducts">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automationCenter == 1)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                        <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                        <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>

                                    <v-card-text 
                                        style="margin-top: 20px;"
                                        v-if="qtyChannels > 0"
                                    >
                                        <label>Automation Items</label>

                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 20%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 30%">
                                                            Description
                                                        </th>
                                                        <th class="text-center" style="width: 10%">
                                                            Qty
                                                        </th>
                                                        <th class="text-right" style="width: 10%" v-if="showMoreDetailProducts">
                                                            Price
                                                        </th>
                                                        <th class="text-right" style="width: 10%" v-if="showMoreDetailProducts">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automatable == 1 && prd.notAutomatable == 0)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                        <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.price }}</td>
                                                        <td class="text-right" v-if="showMoreDetailProducts">$ {{ item.total }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                            Grand Total
                                                        </th>
                                                        <th :class="showMoreDetailProducts ? 'text-right' : 'text-center'" style="color: var(--color__main); font-size: 20px;">
                                                            {{ grandTotalProducts }}
                                                        </th>
                                                    </tr>
                                                    <tr v-if="showMoreDetailProducts">
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px;">
                                                            Budget
                                                        </th>
                                                        <th class="text-right"  style="width: 15%; color: var(--color__main); font-size: 20px;">
                                                            {{ serviceRequest.estimatedBudgetFormatted }}
                                                        </th>
                                                    </tr>
                                                    <tr v-if="showMoreDetailProducts">
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-center" v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  v-if="showMoreDetailProducts"></th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px;">
                                                            Zillow
                                                        </th>
                                                        <th class="text-right"  style="color: var(--color__main); font-size: 20px;">
                                                            {{ estimatedValueZillow }}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                    </v-row>

                    <v-row v-if="showMoreDetailProducts">
                        <v-col
                            cols="12"
                            lg="7"
                            md="12"
                            sm="12"
                        >
                            &nbsp;
                        </v-col>
                        <v-col
                            cols="12"
                            lg="5"
                            md="12"
                            sm="12"
                        >
                            <zillowTerms :address="serviceRequest.addressSelected.address" :lgWidthTerms="'8'" :lgWidthLogo="'4'"  />
                        </v-col>
                    </v-row>

                </v-stepper-content>

                <!-- STEP 3: PROPOSAL  -->

                <v-stepper-content step="3">
                    
                    <JobInformation 
                        :id="serviceRequest.id"
                        :showJobSummary="true"
                        :project="projectSelected"
                        :refreshJob="refreshJob"
                        :showQuickAccess="false"
                        :showButtonsCreateEditProject="serviceRequest.status < 6"
                        :showButtonsCreateEditVersion="serviceRequest.status < 6"
                        v-on:changeProject="updateProject"
                    />

                    <!-- PRODUCT -->
                    <v-row>
                        <v-col
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Selected Items
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-simple-table
                                            fixed-header
                                            style="cursor: pointer;"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 20%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 50%">
                                                            Description
                                                        </th>
                                                        <th class="text-right" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automation == false || prd.notAutomatable == 1)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td class="text-right">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot v-if="qtyChannels == 0">
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                            Grand Total
                                                        </th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                            {{ grandTotalProducts }}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col
                            v-if="qtyChannels > 0"
                            cols="12"
                            style="padding: 0px !important;"
                        >

                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Automation
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        
                                        <label>Automation Controller</label>

                                        <v-simple-table
                                            fixed-header
                                            style="cursor: pointer;"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 20%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 50%">
                                                            Description
                                                        </th>
                                                        <th class="text-right" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automationCenter == 1)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td class="text-right">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>

                                    <v-card-text style="margin-top: 20px;">
                                        
                                        <label>Automation Items</label>

                                        <v-simple-table
                                            fixed-header
                                            style="cursor: pointer;"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" style="width: 20%">
                                                            Category / Sub Category
                                                        </th>
                                                        <th class="text-left" style="width: 20%">
                                                            Product
                                                        </th>
                                                        <th class="text-left" style="width: 50%">
                                                            Description
                                                        </th>
                                                        <th class="text-right" style="width: 10%">
                                                            Qty
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(item, index) in listProductSelecteds.filter(prd => prd.automatable == 1 && prd.notAutomatable == 0)" :key="index"
                                                    >
                                                        <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                                        <td>{{ item.productDescription }}</td>
                                                        <td>{{ item.productDetails }}</td>
                                                        <td class="text-right">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                            Grand Total
                                                        </th>
                                                        <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                            {{ grandTotalProducts }}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                                
                            </v-card-text>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col
                            v-if="serviceRequest.status == 6"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-col cols="4">
                                            Contract
                                        </v-col>
                                        <v-col cols="8" style="text-align: right;">
                                            <v-chip
                                                style="font-size: 12px !important;"
                                                :color="statusColorContract"
                                                outlined
                                                dark
                                            >
                                                {{ statusDescriptionContract }}
                                            </v-chip>

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip
                                                        style="font-size: 12px !important; margin-left: 10px;"
                                                        v-on="on"
                                                        color="primary"
                                                        outlined
                                                        dark
                                                        link
                                                        @click="showDialogDocusign(TYPE_DOCUMENT.CONTRACT)"
                                                    >
                                                        Docusign
                                                    </v-chip>
                                                </template>
                                                <span>Click to view docusign options</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-row>
                                            <v-col
                                                v-if="isAdminProfile"
                                                cols="12"
                                                lg="4"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 5:</strong> Complete the homeowner responsibility form</span>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="showDialogAdditionalInfoContract()"
                                                        >
                                                            <v-icon left>mdi-file-download-outline</v-icon>
                                                            Add additional information
                                                        </v-btn>
                                                    </template>
                                                    <span>Add additional information to the contract</span>
                                                </v-tooltip>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                :lg="isAdminProfile == true ? 4 : 5"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 1:</strong> First you must download the contract and send it to the customer for signing</span>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="downloadFile(URL_ATTACHMENT_CONTRACT, id, 'application/pdf', 'Contract.pdf');"
                                                        >
                                                            <v-icon left>mdi-file-download-outline</v-icon>
                                                            Download
                                                        </v-btn>
                                                    </template>
                                                    <span>Download PDF</span>
                                                </v-tooltip>

                                            </v-col>

                                            <v-col
                                                v-if="isAdminProfile == false"
                                                style="text-align: center;"
                                                cols="12"
                                                lg="2"
                                                md="12"
                                                sm="12"
                                            >                                            
                                                <v-divider vertical></v-divider>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                :lg="isAdminProfile == true ? 4 : 5"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 2:</strong> Upload the contract signed</span>
                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="uploadSignedContract()"
                                                        >
                                                            <v-icon left>mdi-file-upload-outline</v-icon>
                                                            Upload Signed Document
                                                        </v-btn>
                                                    </template>
                                                    <span>Upload Signed Document</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col
                            v-if="serviceRequest.status == 6"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-col cols="4">
                                            Scope of Work
                                        </v-col>
                                        <v-col cols="8" style="text-align: right;">
                                            <v-chip
                                                style="font-size: 12px !important;"
                                                :color="statusColorScopeOfWork"
                                                outlined
                                                dark
                                            >
                                                {{ statusDescriptionScopeOfWork }}
                                            </v-chip>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                lg="5"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 3:</strong> You must download the proposal and send it to the customer for signing</span>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="showDialogTotalProposal = true;"
                                                        >
                                                            <v-icon left>mdi-file-download-outline</v-icon>
                                                            Download
                                                        </v-btn>
                                                    </template>
                                                    <span>Download PDF</span>
                                                </v-tooltip>

                                            </v-col>
                                            <v-col
                                                style="text-align: center;"
                                                cols="12"
                                                lg="2"
                                                md="12"
                                                sm="12"
                                            >
                                            
                                                <v-divider vertical></v-divider>

                                            </v-col>

                                            <v-col
                                                cols="12"
                                                lg="5"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 4:</strong> Upload the proposal signed to complete this process and proceed to the technical specification phase</span>
                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="uploadSignedScopeOfWork()"
                                                        >
                                                            <v-icon left>mdi-file-upload-outline</v-icon>
                                                            Upload Signed Document
                                                        </v-btn>
                                                    </template>
                                                    <span>Upload Signed Document</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col
                            v-if="serviceRequest.status == 6"
                            cols="12"
                            style="padding: 0px !important;"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-col cols="4">
                                            Homeowner`s Responsibility
                                        </v-col>
                                        <v-col cols="8" style="text-align: right;">
                                            <v-chip
                                                style="font-size: 12px !important;"
                                                :color="statusColorHOResp"
                                                outlined
                                                dark
                                            >
                                                {{ statusDescriptionHOResp }}
                                            </v-chip>
                                        </v-col>                                        
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                lg="4"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 5:</strong> Complete the homeowner responsibility form</span>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="showDialogHO()"
                                                        >
                                                            <v-icon left>mdi-file-download-outline</v-icon>
                                                            Open form
                                                        </v-btn>
                                                    </template>
                                                    <span>Open form</span>
                                                </v-tooltip>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                lg="4"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 6:</strong> Download the form document</span>
                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="downloadFile(URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY, id, 'application/pdf', 'HomeownerResponsibility.pdf');"
                                                        >
                                                            <v-icon left>mdi-file-download-outline</v-icon>
                                                            Download
                                                        </v-btn>
                                                    </template>
                                                    <span>Download Document</span>
                                                </v-tooltip>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                lg="4"
                                                md="12"
                                                sm="12"
                                            >
                                                <span><strong>Step 7:</strong> Upload the form signed</span>
                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                            class="mx-2"
                                                            v-on="on"
                                                            @click="uploadSignedHomeownerResponsability()"
                                                        >
                                                            <v-icon left>mdi-file-upload-outline</v-icon>
                                                            Upload Signed Document
                                                        </v-btn>
                                                    </template>
                                                    <span>Upload Signed Document</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <!-- STEP 4: TECHNICAL SPECIFICATION -->

                <v-stepper-content step="4">
                    
                    <JobInformation 
                        :id="serviceRequest.id"
                        :showJobSummary="true"
                        :project="projectSelected"
                        :refreshJob="refreshJob"
                        :showButtonsCreateEditProject="false"
                        :showButtonsCreateEditVersion="false"
                        :showQuickAccess="false"
                        v-on:changeProject="updateProject"
                    />
                    
                    <TechnicalSpecification 
                        :project="projectSelected"
                        :listProjects="listProjects"
                        :showBackButton="false"
                        :showNotificationApprovedOrDenied="false"
                        v-on:cancel="cancel"
                    />

                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>
            

        <v-dialog
            v-model="showDialogTotalProposal"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Download PDF Proposal
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-switch 
                                v-model="showTotalProposal"
                                label="Show only the total value of the proposal?"
                                :value=true
                                color="var(--color__main)"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss"
                            style=""
                            outlined
                            @click="showDialogTotalProposal = false"
                        >
                            Dismiss
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            outlined
                            @click="downloadProposal()"
                        >
                            Download
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Buttons: NEXT | BACK -->
        
        <div 
            style="padding-top: 15px;"
            v-if="projectSelected != null && projectSelected != undefined && projectSelected.status > 7"
            class="text-center"
        >
            
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="back()"
                            >
                                Back
                            </v-btn>
                        </template>
                        <span>Back</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <v-card-actions 
            v-else
            class="justify-center"
            style="padding-top: 50px"
        >
            <v-tooltip 
                v-if="showCancelButton"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn 
                        v-if="showCancelButton"
                        class="mx-2"
                        v-on="on"
                        @click="cancel"
                        style="background-color: var(--color__red) !important; color: #ffffff !important;"
                    >
                        Cancel
                    </v-btn>
                </template>
                <span>Cancel</span>
            </v-tooltip>
            
            <v-tooltip 
                v-if="showBackButton"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn 
                        v-if="showBackButton"
                        class="mx-2"
                        v-on="on"
                        :disabled="steps == 1 || projectSelected == null || projectSelected == undefined"
                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                        @click="controlStep('back')"
                    >
                        Back
                    </v-btn>
                </template>
                <span>Back Previous Step</span>
            </v-tooltip>

            <v-tooltip 
                v-if="showNextButton"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-if="showNextButton"
                        class="mx-2"
                        v-on="on"
                        :disabled="projectSelected == null || projectSelected == undefined"
                        @click="controlStep('next')"
                    >
                        Next
                    </v-btn>
                </template>
                <span>Go To Next Step</span>
            </v-tooltip>            
                    
            <v-tooltip 
                v-if="showDownloadAndCreateProposal"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-if="showDownloadAndCreateProposal"
                        class="mx-2"
                        v-on="on"
                        @click="showDialogTotalProposal = true;"
                    >
                        Download Proposal
                    </v-btn>
                </template>
                <span>Download a proposal to send to the customer</span>
            </v-tooltip>

            <v-tooltip 
                v-if="showDownloadAndCreateProposal"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-if="showDownloadAndCreateProposal"
                        class="mx-2"
                        v-on="on"
                        @click="confirmCreateProposal()"
                    >
                        Create Proposal
                    </v-btn>
                </template>
                <span>Create Proposal</span>
            </v-tooltip>

        </v-card-actions>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <UploadFile 
            v-on:methodConfirmAfterUpload="dialogUploadFile.methodAfterUpload"
            :showUploadFile.sync="dialogUploadFile.show"
            :idKey="dialogUploadFile.idKey"
            :reload="dialogUploadFile.reload"
            :acceptType="dialogUploadFile.acceptType"
            :typeAttachment="dialogUploadFile.typeAttachment"
            :typeDocument="dialogUploadFile.typeDocument"
            :multipleFiles="dialogUploadFile.multipleFiles"
            :showMsgConfirmation="dialogUploadFile.showMsgConfirmation"
            :msgConfirmation="dialogUploadFile.msgConfirmation"
        />

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :showAsCarrousel.sync="dialogShowFiles.showAsCarrousel"
            :reload="dialogShowFiles.reload"
            :showFilters="dialogShowFiles.showFilters"
            :listParams="dialogShowFiles.listParams"
        />

        <DialogHomeOwnerResponsability 
            v-on:methodConfirmToCall="dialogHOResp.methodConfirm"
            :showDialogHOResp.sync="dialogHOResp.show"
            :id="dialogHOResp.id"
        />

        <v-dialog
            v-model="showDialogAdditionalInformationContract"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Additional information in the contract
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>                            
                            <v-textarea
                                v-model="serviceRequest.additionalInformationContract"
                                single-line
                                rows="10"
                                row-height="130"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss"
                            style=""
                            outlined
                            @click="showDialogAdditionalInformationContract = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            outlined
                            @click="saveAdditionalInfoContract()"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DialogDocusign 
            v-on:methodConfirmToCall="dialogDocusign.methodConfirm"
            :showDialogDocusign.sync="dialogDocusign.show"
            :id="dialogDocusign.id"
            :typeDocument="dialogDocusign.typeDocument"
            :customer="dialogDocusign.customer"
        />
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required, requiredFile } from "@/utilities/Rules";
    import zillowTerms from '@/components/Zillow/ZillowTerms';
    import { 
        formaterDecimalBR, 
        formaterDecimalBRServer 
    } from '@/utilities/Utils';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import UploadFile from "@/components/File/UploadFile";
    import ShowFiles from "@/components/File/ShowFiles";
    import JobInformation from "@/components/Services/JobInformation";
    import TechnicalSpecification from "@/components/Services/TechnicalSpecification";
    import DialogHomeOwnerResponsability from "@/components/Services/DialogHomeOwnerResponsability";
    import DialogDocusign from "@/components/Services/DialogDocusign";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT,
        URL_ATTACHMENT_CONTRACT,
        URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY
    } from "@/utilities/Enums";

    export default ({

        components: {
            zillowTerms,
            ActionDialog,
            UploadFile,
            ShowFiles,
            JobInformation,
            TechnicalSpecification,
            DialogHomeOwnerResponsability,
            DialogDocusign
        },

        mixins: [Helpers],

        props: {
            id: {
                default: 0
            },

            setStep: {
                default: 0
            }
        },

        data: vm => ({

            URL_ATTACHMENT_CONTRACT,
            URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY,

            steps: 1,

			serviceRequest: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                appointmentCitySelected: {
                    description: "",
                    stateSelected: {
                        code: ""
                    }
                },

            },

            listProjects: [],
            projectSelected: null,
            refreshJob: false,

            listProductSelecteds: [],
            arrayNodeProduct: [],
            searchProduct: "",
            listTreeViewProduct: [],
            listTemplate: [],
            templateSelected: null,

            idServiceItem: 0,
            itemDescription: "",
            itemNote: "",
            itemStyling: "",
            isStylable: false,
            showDialogItemNote: false,

            unitProductFormat: {
                prefix: '',
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },

            showMoreDetailProducts: false,
            showMoreDetailProductsSW: false,

            grandTotalUpdated: false,
            grandTotalProducts: "$ 0.00",
            discount: "0.00",
            grandTotalProductsUnFormatted: 0,
            estimatedValueZillow: "$ 0.00",

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogHOResp: {
                show: false,
                id: 0,
                methodConfirm: ( () => {} )
            },

            dialogUploadFile: {
                show: false,
                idKey: 0,
                reload: 0,
                acceptType: ".pdf",
                typeAttachment: 0,
                typeDocument: 0,
                multipleFiles: false,
                showMsgConfirmation: true,
                msgConfirmation: "",
                methodAfterUpload: ( () => {} ),
            },

            dialogShowFiles: {
                show: false,
                showAsCarrousel: false,
                reload: 0,
                showFilters: false,
                listParams: []
            },

            dialogDocusign: {
                show: false,
                id: 0,
                typeDocument: null,
                customer: null,
                methodConfirm: ( () => {} )
            },

            showDialogAdditionalInformationContract: false,

            showDialogTotalProposal: false,
            showTotalProposal: true,

            statusColorContract: "var(--color__main)",
            statusDescriptionContract: "Completed",

            statusColorScopeOfWork: "var(--color__main)",
            statusDescriptionScopeOfWork: "Completed",

            statusColorHOResp: "var(--color__main)",
            statusDescriptionHOResp: "Completed",

            validations: {
                file: requiredFile,
                required: required,
                mandatoryProduct (itemProduct) {

                    if ( vm.isProductMandatory(itemProduct) == true && (itemProduct.qty == '0.00' || itemProduct.qty == '' || itemProduct.qty == null))
                        return false
                    else
                        return true
                }
            },
            
            og: Math.pow(10, 2),

            TYPE_DOCUMENT: TYPE_DOCUMENT
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            proposalCreated() {
                return this.steps == 3 && this.serviceRequest.status == 6
            },

            qtyChannels() {
                let centralAutomation = this.listProductSelecteds.filter(prd => prd.automationCenter == 1)[0];

                if (centralAutomation != null && centralAutomation != undefined) {
                    return centralAutomation.channelQty;
                }
                else {
                    return 0;
                }
            },

            automationItemsIsValid() {
                let qtyChannelsAvailable = this.qtyChannels;
                let listAutomatableItems = this.listProductSelecteds.filter(prd => prd.automatable == 1);
                let qtyItemsAutomatable = 0;

                listAutomatableItems.forEach(item => {

                    const qty = item.onlyOneChannelConsumed == 1 ? 1 : item.qty;

                    qtyItemsAutomatable += parseInt(qty);
                });

                if (qtyItemsAutomatable > qtyChannelsAvailable) {
                    return false;
                }
                else {
                    return true;
                }
            },

            showCancelButton() {
                return this.steps <= 3
            },

            showBackButton() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.status != 7
            },

            showNextButton() {
                return this.steps == 1 || this.steps == 2
            },

            showDownloadAndCreateProposal() {
                return this.steps == 3 && this.serviceRequest.status == 3
            },

            isProjectPool() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pool == 1;
            },

            isProjectBBQ() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.bbq == 1;
            },

            isProjectPergola() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pergola == 1;
            },

            isAdminProfile() {
                return this.userLoggedGetters.profile.systemAdmin == 1;
            }
        },

        methods: {
            
            async back() {
                history.go(-1);
            },

            async controlStep (type) {
                if (type === 'next') {

                    let validForm = await this.validateForm(this.steps);
                    
                    if (validForm == true) {
                        this.steps += 1;
                        await this.saveItems();
                        await this.updateStep();
                        this.refreshJob = !this.refreshJob;
                    }
                }
                else if (type === 'back') {
                    switch (this.steps) {
                        case 3: this.steps = 2; break;
                        case 2: this.steps = 1; break;
                        default: this.steps = 1; break;
                    }
                }
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;

                    if (this.setStep != 0) {
                        this.steps = this.setStep;
                    }

                    this.discount = this.serviceRequest.discount;
                    this.grandTotalUpdated = this.serviceRequest.grandTotal > 0;
                    this.grandTotalProducts = this.serviceRequest.grandTotalFormatted;

                    this.getEstimatedValueZillow();
                    this.allDocumentsIsSigned();
                }
            },

            async updateProject(projectSelected, listProjects) {

                this.showLoading();

                this.projectSelected = projectSelected;
                this.listProjects = listProjects;

                if (projectSelected != null && projectSelected != undefined) {

                    this.steps = this.projectSelected.step;

                    if (this.steps <= 3) {
                        await this.updateTreeViewProduct();
                    }
                }

                this.hideLoading();
            },

            async getLists() {
                this.listTemplate = await this.$store.dispatch("templateModule/List");
            },

            async validateForm(step) {

                if (step == 1) {

                    let qtyChannelsAvailable = this.qtyChannels;
                    let hasAutomationAndHasMoreOneCentral = false;
                    let hasAutomationAndMoreItemsThanCentralChannel = false;

                    if (qtyChannelsAvailable > 0) {
                        let qtyCentral = this.listProductSelecteds.filter(prd => prd.automationCenter == 1);   

                        hasAutomationAndHasMoreOneCentral = qtyCentral.length > 1;

                        if (this.automationItemsIsValid == false) {
                            hasAutomationAndMoreItemsThanCentralChannel = true;
                        }
                    }

                    let stylingValidate = '';

                    this.listProductSelecteds.forEach(prd => {
                        if (prd.stylable == 1 && (prd.styling == null || prd.styling == undefined || prd.styling.toString().trim() == '')) {
                            stylingValidate = `You must inform the styling of the product [${prd.productDescription}]`
                        }
                    })

                    if (this.projectSelected == null || this.projectSelected == undefined) {
                        this.showToast("error", "Error!", "No project selected.");
                        return false;
                    }
                    else if (this.listProductSelecteds.length == 0 ) {
                        this.showToast("error", "Error!", "No products selected. You must select at least one.");
                        return false;
                    }
                    else if (hasAutomationAndHasMoreOneCentral == true) {
                        this.showToast("error", "Error!", "Only one automation center can be selected.");
                        return false;
                    }
                    else if (hasAutomationAndMoreItemsThanCentralChannel == true) {
                        this.showToast("error", "Error!", "Number of automation items is greater than the number of central channels.");
                        return false;
                    }
                    else if (stylingValidate != '') {
                        this.showToast("error", "Error!", stylingValidate);
                        return false;
                    }
                    else if (await this.checkCategoryAndSubCategoryMandatory() == false) {
                        return false;
                    }
                    else if (await this.checkMandatory()) {
                        return true;
                    }
                }
                else if (step == 2) {

                    return true;
                }
                else {
                    return true;
                }
            },

            async updateTreeViewProduct() {
                const idServiceProject = await this.getIDProject();

                const request = { 
                    id: idServiceProject, 
                    type: 'SERVICE',
                    showOnlyCreatedItems: false, 
                    fromHistory: false 
                };

                this.listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", request);

                await this.updateItemsProduct();
            },

            async updateItemsProduct() {

                const idServiceProject = await this.getIDProject();

                if (idServiceProject != 0) {
                
                    this.listProductSelecteds = [];
                    let grandTotal = 0;
                    this.listTreeViewProduct.forEach(itemCategory => {
                        itemCategory.children.forEach(itemSubCategoryOrProduct => {
                            
                            if (itemSubCategoryOrProduct.type == 'SubCategory') {

                                itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                    if (itemProduct.qty != null && itemProduct.qty != undefined && itemProduct.qty != "0.00" && itemProduct.qty != "" ) {

                                        let qty = parseFloat(itemProduct.qty == '' ? 0 : itemProduct.qty);
                                        
                                        if (qty <= 0) {
                                            this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                            itemProduct.qty = '';
                                        }
                                        else {

                                            let total = (qty * itemProduct.price).toFixed(2);

                                            this.listProductSelecteds.push({
                                                id: itemProduct.idServiceItem,
                                                idService: idServiceProject,
                                                idCategory: itemCategory.id,
                                                categoryDescription: itemCategory.name,
                                                idSubCategory: itemSubCategoryOrProduct.id,
                                                subCategoryDescription: itemSubCategoryOrProduct.name,
                                                productDescription: itemProduct.name,
                                                productDetails: itemProduct.details,
                                                idProduct: itemProduct.idTable,
                                                qty: itemProduct.qty,
                                                automation: itemProduct.automatable == 1 || itemProduct.automationCenter == 1,
                                                automatable: itemProduct.automatable,
                                                automationCenter: itemProduct.automationCenter,
                                                channelQty: itemProduct.channelQty,
                                                onlyOneChannelConsumed: itemProduct.onlyOneChannelConsumed,
                                                highlight: itemProduct.highlight,
                                                highlightDescription: itemProduct.highlightDescription,
                                                unitySymbol: itemProduct.unitySymbol,
                                                price: formaterDecimalBR(itemProduct.price),
                                                total: formaterDecimalBR(total),
                                                note: itemProduct.note,
                                                styling: itemProduct.styling,
                                                customOrder: itemProduct.customOrder,
                                                stylable: itemProduct.stylable,
                                                optional: itemProduct.optional,
                                                notAutomatable: itemProduct.notAutomatable,
                                                requiredAtLeastOneProductCategory: itemProduct.requiredAtLeastOneProductCategory,
                                                requiredAtLeastOneProductSubCategory: itemProduct.requiredAtLeastOneProductSubCategory,
                                                requiredOnlyOneProductCategory: itemProduct.requiredOnlyOneProductCategory,
                                                requiredOnlyOneProductSubCategory: itemProduct.requiredOnlyOneProductSubCategory
                                            })

                                            grandTotal += parseFloat(total);
                                        }
                                    }
                                    else {
                                        itemProduct.qty = '';
                                    }
                                });
                            }
                            else {
                                
                                if (itemSubCategoryOrProduct.qty != null && itemSubCategoryOrProduct.qty != undefined && itemSubCategoryOrProduct.qty != "0.00" && itemSubCategoryOrProduct.qty != "" ) {

                                    let qty = parseFloat(itemSubCategoryOrProduct.qty == '' ? 0 : itemSubCategoryOrProduct.qty);

                                    if (qty <= 0) {
                                        this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                        itemSubCategoryOrProduct.qty = ''
                                    }
                                    else {
                                        
                                        let total = (qty * itemSubCategoryOrProduct.price).toFixed(2);

                                        this.listProductSelecteds.push({
                                            id: itemSubCategoryOrProduct.idServiceItem,
                                            idService: idServiceProject,
                                            idCategory: itemCategory.id,
                                            categoryDescription: itemCategory.name,
                                            idSubCategory: 0,
                                            subCategoryDescription: "",
                                            productDescription: itemSubCategoryOrProduct.name,
                                            productDetails: itemSubCategoryOrProduct.details,
                                            idProduct: itemSubCategoryOrProduct.idTable,
                                            qty: itemSubCategoryOrProduct.qty,
                                            automation: itemSubCategoryOrProduct.automatable == 1 || itemSubCategoryOrProduct.automationCenter == 1,
                                            automatable: itemSubCategoryOrProduct.automatable,
                                            automationCenter: itemSubCategoryOrProduct.automationCenter,
                                            channelQty: itemSubCategoryOrProduct.channelQty,
                                            onlyOneChannelConsumed: itemSubCategoryOrProduct.onlyOneChannelConsumed,
                                            highlight: itemSubCategoryOrProduct.highlight,
                                            highlightDescription: itemSubCategoryOrProduct.highlightDescription,
                                            unitySymbol: itemSubCategoryOrProduct.unitySymbol,
                                            price: formaterDecimalBR(itemSubCategoryOrProduct.price),
                                            total: formaterDecimalBR(total),
                                            note: itemSubCategoryOrProduct.note,
                                            styling: itemSubCategoryOrProduct.styling,
                                            customOrder: itemSubCategoryOrProduct.customOrder,
                                            stylable: itemSubCategoryOrProduct.stylable,
                                            optional: itemSubCategoryOrProduct.optional,
                                            notAutomatable: itemSubCategoryOrProduct.notAutomatable,
                                            requiredAtLeastOneProductCategory: itemSubCategoryOrProduct.requiredAtLeastOneProductCategory,
                                            requiredAtLeastOneProductSubCategory: itemSubCategoryOrProduct.requiredAtLeastOneProductSubCategory,
                                            requiredOnlyOneProductCategory: itemSubCategoryOrProduct.requiredOnlyOneProductCategory,
                                            requiredOnlyOneProductSubCategory: itemSubCategoryOrProduct.requiredOnlyOneProductSubCategory
                                        })
                                        grandTotal += parseFloat(total);
                                    }
                                }
                                else {
                                    itemSubCategoryOrProduct.qty = ''
                                }
                            }
                        });
                    });

                    this.grandTotalProductsUnFormatted = grandTotal;

                    if (this.grandTotalUpdated == false) {
                        this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(grandTotal * this.og) / this.og))}`;
                    }
                }
            },

            async getEstimatedValueZillow() {

                let zillowRequest = {
                    typeRequest: 2, //Address
                    zipCodeOrAddress: "",
                }

                if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                    zillowRequest.zipCodeOrAddress = this.serviceRequest.addressSelected.address;
                    this.address = this.serviceRequest.addressSelected.address;
                }

                let response = await this.$vanKirkApi.post(`common/zillow`, zillowRequest, {
                        headers: {
                            "content-type": "application/json"
                        }
                    });
                let resultZillow = response.data.result;

                this.estimatedValueZillow = "$ 0,00";
                    
                if (resultZillow && resultZillow.success == true && resultZillow.bundle.length > 0) {
                    this.estimatedValueZillow = resultZillow.bundle[0].zEstimateFormatted;
                }
                else {
                    this.estimatedValueZillow = "Not found"
                }
            },

            async confirmationApplyTemplate() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'Do you want to APPLY the selected Template, overwriting the current SCOPE OF WORK?',
                    methodConfirm: this.applyTemplate,
                    params: 0
                };

            },

            async applyTemplate() {
                this.listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: 0, type: 'SERVICE', showOnlyCreatedItems: false, fromHistory: false });
                let response = await this.$store.dispatch("templateModule/GetById", this.templateSelected.id);

                let listTemplateItem = response.result.listItemResponse;

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                let itemTemplate = listTemplateItem.filter(tpi => tpi.idProduct == itemProduct.idTable)[0];
                                if (itemTemplate != null && itemTemplate != undefined) {
                                    itemProduct.qty = itemTemplate.qty.toString();
                                }
                            });
                        }
                        else {
                            
                            let itemTemplate = listTemplateItem.filter(tpi => tpi.idProduct == itemSubCategoryOrProduct.idTable)[0];
                            if (itemTemplate != null && itemTemplate != undefined) {
                                itemSubCategoryOrProduct.qty = itemTemplate.qty.toString();
                            }
                        }
                    });
                });

                this.updateItemsProduct();
            },

            focusInput(item) {
                this.arrayNodeProduct = []
                this.arrayNodeProduct.push(item.idCategory);
                if (item.idSubCategory != null && item.idSubCategory != undefined && item.idSubCategory != 0)  {
                    this.arrayNodeProduct.push(item.idSubCategory);
                }

                setTimeout(() => {
                    let txtComponent = document.getElementById(`productQty${item.idProduct}`);
                    txtComponent.focus();
                }, 100);

            },

            onOpenTreeViewProduct(items) {
                this.arrayNodeProduct = items
            },

            async cancel() {
                this.$router.push({ path: "/sales/pipeline" });
            },

            async saveItems(silent = false) {

                let idServiceProject = await this.getIDProject();

                const resultService = await this.$store.dispatch("serviceModule/UpdateNoteScopeOfWork", this.serviceRequest);

                if (resultService.success === true) {

                    let listProducts = [];

                    const grandTotalProducts = formaterDecimalBRServer(this.grandTotalProducts);

                    let discount = 0;

                    if (this.discount != null && this.discount != undefined) {
                        discount = this.discount;
                    }

                    this.listProductSelecteds.forEach (item => {
                        listProducts.push({
                            id: item.id,
                            idServiceProject,
                            idProduct: item.idProduct,
                            idBolws: 0,
                            qty: item.qty,
                            newQty: item.qty,
                            price: item.price,
                            notes: item.notes,
                            discount,
                            grandTotal: grandTotalProducts
                        })
                    })

                    if (listProducts.length <= 0) {
                        listProducts.push({
                            id: 0,
                            idServiceProject,
                            qty: '0',
                            newQty: '0',
                            idProduct: 0,
                            price: '0',
                            discount: 0,
                            grandTotal: 0
                        })
                    }

                    const result = await this.$store.dispatch("serviceModule/CreateUpdateItems", { serviceItemRequest: listProducts, createHistory: false, oficial: 0, uploadAddendum: 0 });

                    if (silent == false) {
                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            return true;
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                            return false;
                        }
                    }

                    await this.updateTreeViewProduct();
                }
                else {
                    if (silent == false) {
                        this.showToast("error", "Warning!", resultService.message);
                        return false;
                    }
                }
            },

            async updateStep() {

                let idServiceProject = await this.getIDProject();

                const result = await this.$store.dispatch("serviceModule/UpdateStep", { id: idServiceProject, newStep: this.steps} );

                if (result.success === false) {
                    this.showToast("error", "Warning!", result.message);
                }
            },

            async confirmCreateProposal() {

                let projectNotOK = "";
                let projectAndVersion = "";

                this.listProjects.forEach(itemProject => {

                    if (itemProject.step < 3) {
                        projectNotOK += `- ${itemProject.name} <br />`
                    }

                    projectAndVersion += `${itemProject.name} - [${itemProject.versionName}] <br />`
                });

                if (projectNotOK != "") {
                    // projectNotOK = projectNotOK.substring(0, projectNotOK.length - 2) + "]";
                    this.showToast("error", "Warning!", `You must complete all steps about projects bellow: <br /><br /> ${projectNotOK}`);
                }
                else {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `Would like to create a new PROPOSAL about the projects/version bellow? <br /><br /> ${projectAndVersion}`,
                        methodConfirm: this.createProposal,
                        params: 0
                    };
                }
            },

            async createProposal() {
                
                this.showLoading();

                let updateStatusRequest = {
                    id: this.id,
                    status: 5 //Waiting Approval
                };

                let result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Proposal created successfully");
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.cancel();

                this.hideLoading();
            },

            async deleteItem(item) {
                
                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                if (itemProduct.idTable == item.idProduct) {
                                    itemProduct.qty = ''
                                }
                            });
                        }
                        else {
                            if (itemSubCategoryOrProduct.idTable == item.idProduct) {
                                itemSubCategoryOrProduct.qty = ''
                            }
                        }
                    });
                });

                await this.updateItemsProduct();
            },

            uploadSignedContract() {
                this.dialogUploadFile = {
                    show: true,
                    idKey: this.id,
                    reload: Math.random(),
                    acceptType: ".pdf",
                    typeAttachment: TYPE_ATTACHMENT.SERVICE.value,
                    typeDocument: TYPE_DOCUMENT.CONTRACT.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "You are about to upload the signed contract to the system, confirm this decision?",
                    methodAfterUpload: this.checkAllDocumentsSigned,
                };
            },

            uploadSignedScopeOfWork() {
                this.dialogUploadFile = {
                    show: true,
                    idKey: this.id,
                    reload: Math.random(),
                    acceptType: ".pdf",
                    typeAttachment: TYPE_ATTACHMENT.SERVICE.value,
                    typeDocument: TYPE_DOCUMENT.PROPOSAL.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "You are about to upload the signed scope of work to the system, confirm this decision?",
                    methodAfterUpload: this.checkAllDocumentsSigned,
                };
            },

            uploadSignedHomeownerResponsability() {
                this.dialogUploadFile = {
                    show: true,
                    idKey: this.id,
                    reload: Math.random(),
                    acceptType: ".pdf",
                    typeAttachment: TYPE_ATTACHMENT.SERVICE.value,
                    typeDocument: TYPE_DOCUMENT.HOMEOWNER_RESPONSABILITY.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "You are about to upload the signed homeowner responsability to the system, confirm this decision?",
                    methodAfterUpload: this.checkAllDocumentsSigned,
                };
            },

            async allDocumentsIsSigned() {

                const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE.value, idKey: this.id });

                const contractUploaded = listAttachments.filter(item => item.typeDocument == TYPE_DOCUMENT.CONTRACT.value)[0];
                const scopeOfWorkUploaded = listAttachments.filter(item => item.typeDocument == TYPE_DOCUMENT.PROPOSAL.value)[0];
                const hoRespUploaded = listAttachments.filter(item => item.typeDocument == TYPE_DOCUMENT.HOMEOWNER_RESPONSABILITY.value)[0];

                const statusCompletedColor = "var(--color__main)";
                const statusCompletedDescription = "Completed";
                const statusPendingColor = "var(--color__alert)";
                const statusPendingDescription = "Pending";

                if (contractUploaded == null || contractUploaded == undefined) {
                    this.statusColorContract = statusPendingColor;
                    this.statusDescriptionContract = statusPendingDescription;
                }
                else {
                    this.statusColorContract = statusCompletedColor;
                    this.statusDescriptionContract = statusCompletedDescription;
                }

                if (scopeOfWorkUploaded == null || scopeOfWorkUploaded == undefined) {
                    this.statusColorScopeOfWork = statusPendingColor;
                    this.statusDescriptionScopeOfWork = statusPendingDescription;
                }
                else {
                    this.statusColorScopeOfWork = statusCompletedColor;
                    this.statusDescriptionScopeOfWork = statusCompletedDescription;
                }

                if (hoRespUploaded == null || hoRespUploaded == undefined) {
                    this.statusColorHOResp = statusPendingColor;
                    this.statusDescriptionHOResp = statusPendingDescription;
                }
                else {
                    this.statusColorHOResp = statusCompletedColor;
                    this.statusDescriptionHOResp = statusCompletedDescription;
                }

                return contractUploaded != null && contractUploaded != undefined &&
                       scopeOfWorkUploaded != null && scopeOfWorkUploaded != undefined &&
                       hoRespUploaded != null && hoRespUploaded != undefined
            },

            async checkAllDocumentsSigned() {


                if (await this.allDocumentsIsSigned()) {
                
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: 'You are about to upload all documents to the system, and, from this moment on, only an administrator will be able to edit this service, do you confirm this decision?',
                        methodConfirm: this.uploadProposalSuccess,
                        params: 0
                    };

                }
            },

            async uploadProposalSuccess() {
                
                this.showLoading();

                const updateStatusRequest = {
                    id: this.id,
                    status: 7 //Contracted
                };

                const result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Proposal uploaded successfully");
                    await this.getRegister();
                    this.refreshJob = !this.refreshJob;
                    this.steps = 4;
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.hideLoading();
            },

            async downloadProposal() {

                this.showLoading();

                if (this.showTotalProposal == null || this.showTotalProposal == undefined || this.showTotalProposal == 0) {
                    this.showTotalProposal = false;
                }

                this.$vanKirkApi.get(`service/download-proposal/${this.id}/0/false/${this.showTotalProposal}/false`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        var url = window.URL.createObjectURL(blob);
                        window.open(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Error to generate proposal: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Error to generate proposal: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },

            async checkCategoryAndSubCategoryMandatory() {

                let listCategoryAndSubCategoryAtLeastOne = [];
                let listCategoryAndSubCategoryOnlyOne = [];

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory' && itemSubCategoryOrProduct.requiredAtLeastOneProductSubCategory == 1) {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                const productEmpty = itemProduct.qty == '0.00' || itemProduct.qty == '' || itemProduct.qty == null;
                                const productEmptyQty = itemSubCategoryOrProduct.children.filter(item => item.qty == '0.00' || item.qty == '' || item.qty == null).length;

                                if (productEmpty == true && productEmptyQty == itemSubCategoryOrProduct.children.length) {

                                    const existsItem = listCategoryAndSubCategoryAtLeastOne.filter(item => item.name == itemSubCategoryOrProduct.name)[0];

                                    if (existsItem == null || existsItem == undefined) {
                                        listCategoryAndSubCategoryAtLeastOne.push({
                                            name: itemSubCategoryOrProduct.name,
                                            message: ` - <strong>SubCategory:</strong> ${itemSubCategoryOrProduct.name}<br />`
                                        });
                                    }
                                }
                            });
                        }
                        else if (itemCategory.requiredAtLeastOneProductCategory == 1) {
                            const productEmpty = itemSubCategoryOrProduct.qty == '0.00' || itemSubCategoryOrProduct.qty == '' || itemSubCategoryOrProduct.qty == null;
                            const productEmptyQty = itemCategory.children.filter(item => item.qty == '0.00' || item.qty == '' || item.qty == null).length;

                            if (productEmpty == true && productEmptyQty == itemCategory.children.length) {

                                const existsItem = listCategoryAndSubCategoryAtLeastOne.filter(item => item.name == itemCategory.name)[0];

                                if (existsItem == null || existsItem == undefined) {
                                    listCategoryAndSubCategoryAtLeastOne.push({
                                        name: itemCategory.name,
                                        message: ` - <strong>Category:</strong> ${itemCategory.name}<br />`
                                    });
                                }
                            }
                        }
                        
                        //Only one é required
                        if (itemSubCategoryOrProduct.type == 'SubCategory' && itemSubCategoryOrProduct.requiredOnlyOneProductSubCategory == 1) {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                const productNotEmptyQty = itemProduct.children.filter(item => item.qty != '0.00' && item.qty != '' && item.qty != null).length;

                                if (productNotEmptyQty != 1) {

                                    const existsItem = listCategoryAndSubCategoryOnlyOne.filter(item => item.name == itemSubCategoryOrProduct.name)[0];

                                    if (existsItem == null || existsItem == undefined) {
                                        listCategoryAndSubCategoryOnlyOne.push({
                                            name: itemSubCategoryOrProduct.name,
                                            message: ` - <strong>SubCategory:</strong> ${itemSubCategoryOrProduct.name}<br />`
                                        });
                                    }
                                }
                            });
                        }
                        else if (itemCategory.requiredOnlyOneProductCategory == 1) {

                            const productNotEmptyQty = itemCategory.children.filter(item => item.qty != '0.00' && item.qty != '' && item.qty != null).length;

                            if (productNotEmptyQty != 1) {

                                const existsItem = listCategoryAndSubCategoryOnlyOne.filter(item => item.name == itemCategory.name)[0];

                                if (existsItem == null || existsItem == undefined) {
                                    listCategoryAndSubCategoryOnlyOne.push({
                                        name: itemCategory.name,
                                        message: ` - <strong>Category:</strong> ${itemCategory.name}<br />`
                                    });
                                }
                            }
                        }
                    });
                });

                if (listCategoryAndSubCategoryAtLeastOne.length > 0) {
                    let listItensMessage = '';
                    listCategoryAndSubCategoryAtLeastOne.forEach(item => {
                        listItensMessage += item.message
                    });
                    this.showToast("error", "Error!", `You must choose at least one product from the category or sub-category below: <br /> <br /> ${listItensMessage}`);
                }

                if (listCategoryAndSubCategoryOnlyOne.length > 0) {
                    let listItensMessage = '';
                    listCategoryAndSubCategoryOnlyOne.forEach(item => {
                        listItensMessage += item.message
                    });
                    this.showToast("error", "Error!", `You must choose only one product from the category or sub-category below: <br /> <br /> ${listItensMessage}`);
                }

                return listCategoryAndSubCategoryAtLeastOne.length <= 0 && listCategoryAndSubCategoryOnlyOne.length <= 0;
            },

            async checkMandatory() {

                let allFieldsOK = true;
                let listProductName = ''

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                allFieldsOK = this.validations.mandatoryProduct(itemProduct);

                                if (allFieldsOK == false) {
                                    listProductName += ` - ${itemProduct.name}<br />`;
                                }
                            });
                        }
                        else {
                            allFieldsOK = this.validations.mandatoryProduct(itemSubCategoryOrProduct);

                            if (allFieldsOK == false) {
                                listProductName += ` - ${itemSubCategoryOrProduct.name}<br />`;
                            }
                        }
                    });
                });

                if (listProductName != '') {
                    this.showToast("error", "Error!", `The items bellow are mandatory: <br /> <br /> ${listProductName}`);
                }

                return listProductName == '';
            },

            isProductMandatory(itemProduct) {

                return (itemProduct.mandatoryPool == 1    && this.isProjectPool == true) ||
                       (itemProduct.mandatoryBBQ == 1     && this.isProjectBBQ == true)  ||
                       (itemProduct.mandatoryPergola == 1 && this.isProjectPergola == true)
            },

            isCategoryOrSubCategoryMandatory(itemProduct) {

                const categoryIsMandatory = itemProduct.type == 'Category' && itemProduct.requiredAtLeastOneProductCategory == 1;
                const subCategoryIsMandatory = itemProduct.type == 'SubCategory' && itemProduct.requiredAtLeastOneProductSubCategory == 1;

                return (categoryIsMandatory == true) ||
                       (subCategoryIsMandatory == true)
            },

            async showItemNote(item, type) {

                await this.saveItems(true);

                item = this.listProductSelecteds.filter(prs => prs.idProduct == item.idProduct)[0];

                this.idServiceItem = item.id;

                if (type == 'BOWL') {
                    this.itemDescription = item.bowlDescription;
                }
                else {
                    this.itemDescription = item.productDescription;
                }
                this.itemNote = item.note;
                this.itemStyling = item.styling;
                this.isStylable = item.stylable == 1;
                this.showDialogItemNote = true;
            },

            async saveItemNote() {

                let serviceItemNoteRequest = {
                    id: this.idServiceItem,
                    notes: this.itemNote,
                    styling: this.itemStyling
                }
                
                const result = await this.$store.dispatch("serviceModule/UpdateItemNote", serviceItemNoteRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    await this.updateTreeViewProduct();
                    this.showDialogItemNote = false;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            async showProductsImages(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT.value,
                    idKey: itemProduct.idProduct,
                    isImage: true
                })

                this.dialogShowFiles = {
                    show: true,
                    showAsCarrousel: false,
                    reload: Math.random(),
                    showFilters: false,
                    listParams
                };
            },

            async showProductsDocs(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT_DOCS.value,
                    idKey: itemProduct.idProduct,
                    isImageAndDoc: true
                })

                this.dialogShowFiles = {
                    show: true,
                    reload: Math.random(),
                    showFilters: false,
                    listParams
                };
            },

            async checkDiscount(discountValue) {
                const discountSalesPerson = this.userLoggedGetters.discount;

                if (discountValue > 0 && discountValue > discountSalesPerson) {
                    this.showToast('error', 'Warning', `Discount above the permitted ${this.userLoggedGetters.discountFormatted} %`);
                    this.discount = '0.00';
                    this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(this.grandTotalProductsUnFormatted * this.og) / this.og))}`;
                    this.grandTotalUpdated = false;
                    return false;
                }
                
                return true;
            },

            async calculateTotal(type) {

                if (type == 'd') { //discount
                    const discountUnformatted = parseFloat(this.discount);

                    if (await this.checkDiscount(discountUnformatted) == true) {
                        const newValue = this.grandTotalProductsUnFormatted - (this.grandTotalProductsUnFormatted * (discountUnformatted / 100));
                        this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(newValue * this.og) / this.og))}`;
                    }
                }
                else if (type == 'v') { //value
                    const newValueUnformatted = formaterDecimalBR(this.grandTotalProducts);
                    this.grandTotalUpdated = newValueUnformatted != this.grandTotalProductsUnFormatted;

                    if (newValueUnformatted < this.grandTotalProductsUnFormatted) {
                        const rest = this.grandTotalProductsUnFormatted - parseFloat(newValueUnformatted);
                        const newDiscount = Math.round(Math.floor(  ((rest / this.grandTotalProductsUnFormatted) * 100) * this.og) / this.og);

                        if (await this.checkDiscount(newDiscount) == true) {
                            this.discount = formaterDecimalBR(newDiscount);
                        }
                    }
                    else {
                        this.discount = '0.00';
                    }
                }
            },

            async updateOptional(item) {

                let optional = 0;

                if (item.optional != null && item.optional != undefined) {
                    optional = item.optional == true ? 1 : 0;
                }

                await this.saveItems(true);

                item = this.listProductSelecteds.filter(prs => prs.idProduct == item.idProduct)[0];

                let serviceItemOptionalRequest = {
                    id: item.id,
                    optional
                }
                
                const result = await this.$store.dispatch("serviceModule/UpdateItemOptional", serviceItemOptionalRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    await this.updateTreeViewProduct();
                    this.showDialogItemNote = false;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            async updateNotAutomatable(item) {

                let notAutomatable = 0;

                if (item.notAutomatable != null && item.notAutomatable != undefined) {
                    notAutomatable = item.notAutomatable == true ? 1 : 0;
                }

                await this.saveItems(true);

                item = this.listProductSelecteds.filter(prs => prs.idProduct == item.idProduct)[0];

                let serviceItemNotAutomatableRequest = {
                    id: item.id,
                    notAutomatable
                }
                
                const result = await this.$store.dispatch("serviceModule/UpdateItemNotAutomatable", serviceItemNotAutomatableRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    await this.updateTreeViewProduct();
                    this.showDialogItemNote = false;
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                    return false;
                }
            },

            getDescriptionToolTipProduct(item) {
                if (this.userLoggedGetters.profile.systemAdmin == 1 && item.type == 'Product') {
                    return 'Double click to edit product';
                }
                else {
                    return '';
                }
            },

            openProductForm(item) {                
                if (this.userLoggedGetters.profile.systemAdmin == 1 && item.type == 'Product') {
                    this.$router.push({ name: "productForm", params: { id: item.idTable } });
                }
            },

            showDialogHO() {
                this.dialogHOResp = {
                    show: true,
                    id: this.id,
                    methodConfirm: () => {}
                }
            },

            showDialogAdditionalInfoContract() {
                this.showDialogAdditionalInformationContract = true;
            },

            async saveAdditionalInfoContract() {

                const request = {
                    idService: this.id,
                    additionalInformationContractRequest: {
                        notes: this.serviceRequest.additionalInformationContract
                    }
                }

                const result = await this.$store.dispatch("serviceModule/UpdateAdditionalInfoContract", request);

                if (result.success) {
                    this.showToast("success", "Success!", "Additional information updated successfully!");                    
                    this.showDialogAdditionalInformationContract = false;
                }
            },

            getStyleColor(item) {
                let color = 'color: var(--color__scopeofwork_product);';

                if (item.type == 'Category' || (item.type == 'Product' && item.level == 1)) {

                    color = 'color: var(--color__scopeofwork_category); font-weight: bold;';
                }
                else if (item.type == 'SubCategory') {
                    color = 'color: var(--color__scopeofwork_subcategory);';
                }
                else {
                    color = 'color: var(--color__scopeofwork_product);';
                }

                return color;
            },

            async showDialogDocusign(typeDocument) {

                const customerSelected = this.serviceRequest.customerSelected;

                let customer = {
                    name: '',
                    email: ''
                }

                if (customerSelected != null && customerSelected != undefined) {
                    customer.name = customerSelected.name;
                }
                customer.email = this.serviceRequest.email;

                this.dialogDocusign = {
                    show: true,
                    id: this.id,
                    typeDocument,
                    customer,
                    methodConfirm: () => {
                        this.statusColorContract = "var(--color__alert)";
                        this.statusDescriptionContract = "Send by docusign";
                    }
                }
            },
        },

        async created() {

            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .v-stepper__content {
        padding: 0px !important;
    }
</style>

<style>

    input[name="qtyField"] {
        text-align: center !important;
    }

    input[name="discount"], input[name="grandTotal"] {
        text-align: right !important;
    }

    .v-text-field__suffix {
        font-size: 12px !important;
        font-weight: bold;
    }

    .v-data-table__wrapper {
        overflow-y: hidden !important;
    }
</style>
<template>
    <div>
        <v-dialog
            v-model="showDialogDocusign"
            transition="dialog-top-transition"
            persistent
            width="80%"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Docusign
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <table style="width: 100%; border: none; font-size: 14px; margin-bottom: 20px;">
                                <tr><td class="column"><strong>Document</strong></td><td> {{ typeDocumentDescription }}</td></tr>
                                <tr><td class="column"><strong>Customer Name</strong></td><td> {{ customerInfo.name }}</td></tr>
                                <tr><td class="column"><strong>Customer Email</strong></td><td> {{ customerInfo.email }}</td></tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-data-table
                                :headers="headersHistory"
                                :items="listHistory"
                                :items-per-page="10"
                                no-data-text="No history registered"
                                :hide-default-footer="true"
                            >
                                <template v-slot:item.statusDescription="{ item }">
                                    <v-chip
                                        :color="getStatusColor(item.status)"
                                        outlined
                                        dark
                                    >
                                        {{ item.statusDescription }}
                                    </v-chip>
                                </template>
                                
                                <template v-slot:item.recipientsDescription="{ item }">
                                    <div v-html="item.recipientsDescription"></div>
                                </template>
                                
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :showButtons="{
                                            checkSignature: item.status == 1,
                                        }"
                                        @confirmCheckSignature="confirmCheckSignature"
                                    />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonDismiss v-btn-large"
							outlined
							@click="dismiss"
						>
							Dismiss
						</v-btn>
                        <v-btn
                            class="mx-2 v-btn-large"
                            outlined
                            @click="sendDocumentConfirmation"
                        >
                            Send Document
                        </v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import ActionList from "@/components/Layout/ActionList";

	export default {

        components: {
            ActionDialog,
            ActionList
        },

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },
			
            typeDocument: {
                type: Object,
                default: null
            },

            customer: {
                type: Object,
                default: null
            },  

			showDialogDocusign: {
				default: false
			},
		},

        // watch: {
        //     async "customer"() {
        //         console.log('this.customer 1', this.customer);
        //     },
        // },

        computed: {
            typeDocumentDescription() {
                if (this.typeDocument != null && this.typeDocument != undefined) {
                    return this.typeDocument.description
                }

                return '';
            },

            customerInfo() {
                if (this.customer != null && this.customer != undefined) {
                    return {
                        name: this.customer.name,
                        email: this.customer.email
                    }
                }

                return {
                    name: '',
                    email: ''
                }
            }
        },
		
        data: () => ({

            listHistory: [],
            headersHistory: [
                { text: "Envelop ID", value: "envelopId", sortable: true },
                { text: "Send On", value: "dateRegisterFormatted", sortable: true },
                { text: "Send By", value: "userNameRegistered", sortable: true },
                { text: "Recipients", value: "recipientsDescription", sortable: true },
                { text: "Completed On", value: "dateCompletedFormatted", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],
			
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
		}),

        methods: {

            dismiss() {
                this.$emit('update:showDialogDocusign', false);
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1:
                        color = 'var(--color__status_pending)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async sendDocumentConfirmation() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will SEND the [${this.typeDocumentDescription}] to customer ${this.customerInfo.name} to sign, confirm your decision?`,
                    methodConfirm: this.sendDocument,
                    params: 0
                };

            },

            async sendDocument() {

                this.showLoading();

                const DocusignRequest = {
                    id: this.id,
                    typeDocument: this.typeDocument.value
                }

                const result = await this.$store.dispatch("docusignModule/SendDocument", DocusignRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Document sent with successfully!");
                
                    this.$emit('methodConfirmToCall');
                    this.dismiss();
                }

                this.hideLoading();
            },

            async confirmCheckSignature() {
                return true;
            }

            // async confirm() {

            //     let idUser = 0;

            //     if (this.userSelected != null && this.userSelected != undefined) {
            //         idUser = this.userSelected.id;
            //     }

            //     if (idUser == 0) {
            //         this.showToast("error", "Error!", "Please, select a Supervisor to complete the action!");
            //     }
            //     else {
                
            //         let updateProjectSupervisor = {
            //             id: this.id,
            //             idUser
            //         };

            //         let result = await this.$store.dispatch("serviceModule/SetProjectSupervisor", updateProjectSupervisor);

            //         if (result.success) {
            //             await this.updateStatus();
            //             this.showToast("success", "Success!", "Supervisor assigned successfully!");
                    
            //             this.$emit('methodConfirmToCall');
            //             this.cancel();
            //         }
            //         this.cancel();
            //     }
            // }
        },

        async created() {

            this.listHistory = [
                {
                    envelopId: '166dbc79-355a-43a0-b767-eba4673b95d5',
                    dateRegisterFormatted: '11/06/2024 10:00 AM',
                    userNameRegistered: 'Admin',
                    recipientsDescription: 'Idarmiz (bueno@voxcog.com) <br /> Robert (robert@vankirkpools.com)',
                    dateCompletedFormatted: '',
                    status: 1,
                    statusDescription: 'Pending',
                },
                // {
                //     envelopId: '166dbc79-355a-43a0-b767-eba4673b95d5',
                //     dateRegisterFormatted: '10/07/2024 10:00 AM',
                //     userNameRegistered: 'Admin',
                //     recipientsDescription: 'Idarmiz (bueno@voxcog.com) <br /> Robert (robert@vankirkpools.com)',
                //     dateCompletedFormatted: '11/07/2024 09:25 AM',
                //     status: 2,
                //     statusDescription: 'Completed',
                // }
            ]
        }
    };
</script>

<style scoped>
    .column {
        width: 130px;
        height: 30px;
    }
</style>
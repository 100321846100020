var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","persistent":"","width":"80%","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogDocusign),callback:function ($$v) {_vm.showDialogDocusign=$$v},expression:"showDialogDocusign"}},[_c('v-card',[_c('v-card-title',{staticClass:"lighten-2 formDialog",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)","font-size":"17px"}},[_vm._v(" Docusign ")]),_c('v-card-text',{staticStyle:{"margin-top":"20px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('table',{staticStyle:{"width":"100%","border":"none","font-size":"14px","margin-bottom":"20px"}},[_c('tr',[_c('td',{staticClass:"column"},[_c('strong',[_vm._v("Document")])]),_c('td',[_vm._v(" "+_vm._s(_vm.typeDocumentDescription))])]),_c('tr',[_c('td',{staticClass:"column"},[_c('strong',[_vm._v("Customer Name")])]),_c('td',[_vm._v(" "+_vm._s(_vm.customerInfo.name))])]),_c('tr',[_c('td',{staticClass:"column"},[_c('strong',[_vm._v("Customer Email")])]),_c('td',[_vm._v(" "+_vm._s(_vm.customerInfo.email))])])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersHistory,"items":_vm.listHistory,"items-per-page":10,"no-data-text":"No history registered","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.statusDescription",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescription)+" ")])]}},{key:"item.recipientsDescription",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.recipientsDescription)}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionList',{attrs:{"showButtons":{
                                            checkSignature: item.status == 1,
                                        }},on:{"confirmCheckSignature":_vm.confirmCheckSignature}})]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"padding":"15px !important"}},[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-btn',{staticClass:"mx-2 dialogButtonDismiss v-btn-large",attrs:{"outlined":""},on:{"click":_vm.dismiss}},[_vm._v(" Dismiss ")]),_c('v-btn',{staticClass:"mx-2 v-btn-large",attrs:{"outlined":""},on:{"click":_vm.sendDocumentConfirmation}},[_vm._v(" Send Document ")])],1)])],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }